import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/navigation';

document.addEventListener('DOMContentLoaded', () => {
	new Swiper('.swiper.highlights', {
		modules: [Navigation],
		slidesPerView: 1,
		spaceBetween: 0,
		speed: 250,
		loop: true,
		createElements: true,

		navigation: {
			enabled: true,
		},

		breakpoints: {
			1230: {
				slidesPerView: 4,

				navigation: {
					enabled: false,
				},
			},
			869: {
				slidesPerView: 3,

				navigation: {
					enabled: true,
				},
			},
			600: {
				slidesPerView: 2,

				navigation: {
					enabled: true,
				},
			},
		},
	});
});
